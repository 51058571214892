import axios from "axios";
import Swal from "sweetalert2";
import { getSubdomain } from "tldts";
import { Tooltip } from 'react-tooltip';
import { MyDropzone } from "views/meet/settings";
import { WhitelabelContext } from "app/whitelabel";
import { useState, useEffect, useContext } from "react";
import CustomModal from "components/custom/customModal";
import CustomButton from "components/custom/customButton";
import { Animate, Button, Checkbox, PageTitle, TextInput } from "components/lib";
import { UilPen, UilQuestionCircle, UilCheckCircle, UilTrash } from "@iconscout/react-unicons";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

export function Whitelabel() {
    const [loading, setLoading] = useState(false);
    const [agencyName, setAgencyName] = useState('');
    const [customDomain, setCustomDomain] = useState('');
    const [whitelabelCalendar, setWhitelabelCalendar] = useState(false);
    const [showCustomDomainModal, setShowCustomDomainModal] = useState(false);
    const { whitelabelDetails, setWhitelabelDetails, fetchWhitelabelData } = useContext(WhitelabelContext);

    useEffect(() => {
        if (whitelabelDetails) {
            const { agencyName, customDomain, whitelabelCalendar } = whitelabelDetails;
            setAgencyName(agencyName);
            setCustomDomain(customDomain);
            setWhitelabelCalendar(whitelabelCalendar);
        }
    }, [whitelabelDetails]);

    const handleAgencyNameChange = async () => {
        try {
            if (agencyName?.trim().length < 4) {
                setAgencyName(whitelabelDetails?.agencyName);
                return Toast.fire({ icon: 'error', title: "Agency name must be of at least 4 characters!" });
            }
            const response = await axios.post('/api/whitelabel', { agencyName: agencyName?.trim() });
            setAgencyName(response.data.data.agencyName);
            setWhitelabelDetails({...whitelabelDetails, agencyName: response.data.data.agencyName});
            Toast.fire({ icon: 'success', title: 'Updated the Agency Name!' });
        } catch (error) {
            setAgencyName(whitelabelDetails?.agencyName);
            Toast.fire({ icon: 'error', title: 'Could not update the Agency Name!' });
        }
    }

    const handleWhitelabelCalendarChange = async () => {
        const updatedStatus = !whitelabelCalendar;
        try {
            setWhitelabelCalendar(updatedStatus);
            const response = await axios.post('/api/whitelabel', { whitelabelCalendar: updatedStatus });
            setWhitelabelDetails({...whitelabelDetails, whitelabelCalendar: response.data.data.whitelabelCalendar});
            const message = response.data.data.whitelabelCalendar === true ? 'Calendars will be whitelabelled!' : 'Calendars will not be whitelabelled!';
            Toast.fire({ icon: 'success', title: message });
        } catch (error) {
            setWhitelabelCalendar(!updatedStatus);
            Toast.fire({ icon: 'error', title: 'Could not update the status for Whitelabelling the calendar!' });
        } finally {
            setLoading(false);
        }
    }

    const handleCustomDomainChange = async () => { 
        try {
            setLoading(true);
            const response = await axios.post('/api/whitelabel', { customDomain: customDomain });
            setWhitelabelDetails({...whitelabelDetails, customDomain: response.data.data.customDomain, isVerified: false});
            Toast.fire({ icon: 'success', title: 'Custom Domain updated!' });
            setShowCustomDomainModal(true);
        } catch (error) {
            Toast.fire({ icon: 'error', title: 'Could not update the Custom Domain!' });
        } finally {
            setLoading(false);
        }
    }

    const handleCustomDomainDelete = async () => {
        try {
            await axios.delete('/api/deleteCustomDomain');
            setWhitelabelDetails({...whitelabelDetails, customDomain: '', isVerified: false});
            Toast.fire({ icon: 'success', title: 'Deleted the Custom Domain!' });
        } catch (error) {
            Toast.fire({ icon: 'error', title: 'Could not delete the Custom Domain!' })
        }
    }
    
    const deleteCustomDomain = async () => {
        Swal.fire({
          icon: 'info',
          title: `Are you sure you want to delete the custom domain?`,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: '#D14343',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                handleCustomDomainDelete();
                } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error in deleting custom domain!',
                    showCancelButton: false,
                    confirmButtonText: `Ok`,
                    confirmButtonColor: '#0066ff',
                });
                }
            }
        });
    };

    const handleCustomDomainVerification = async () => {
        try {
            await axios.patch('/api/verifyCustomDomain');
            setWhitelabelDetails({...whitelabelDetails, isVerified: true});
            setShowCustomDomainModal(false);
            Toast.fire({ icon: 'success', title: 'Custom Domain verified successfully!' });
        } catch (error) {
            Toast.fire({ icon: 'error', title: error.response.data.message });
        }
    }

    const CustomProfileImageDashboard = ({ profileImage, forFavicon }) => {
        const [showCustomProfileImageModal, setShowCustomProfileImageModal] = useState(false);
        const [customProfileImage, setCustomProfileImage] = useState(profileImage);

        const dimensions = {
            height: forFavicon ? 64 : 30,
            width: forFavicon ? 64 : 180
        }

        const handleSaveImage = async () => {
            if (customProfileImage instanceof File) {
                const formData = new FormData();
                formData.append(`${forFavicon ? 'favicon' : 'headerLogo'}`, customProfileImage);
                try {
                    const response = await axios.post('/api/whitelabel', formData);
                    setCustomProfileImage(forFavicon ? response.data.data.favicon : response.data.data.headerLogo);
                    const updatedWhitelabelDetails = { ...whitelabelDetails };
                    if (forFavicon) updatedWhitelabelDetails.favicon = response.data.data.favicon;
                    else updatedWhitelabelDetails.headerLogo = response.data.data.headerLogo;
                    setWhitelabelDetails(updatedWhitelabelDetails);
                    Toast.fire({
                        icon: 'success',
                        title: 'Image updated.',
                    });
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: "Couldn't save image.",
                    });
                    setCustomProfileImage(profileImage);
                }
            } else setCustomProfileImage(profileImage);
            setShowCustomProfileImageModal(false);
        };

        return (
            <div className="custom-profile-image-dashboard">
                <img
                    className="custom-profile-image-dashboard-image"
                    src={customProfileImage}
                    alt="Preview Image"
                    style={{ width: forFavicon ? 64 : 180, height: forFavicon ? 64 : 30 }}
                />
                <span
                    className="custom-profile-image-dashboard-image-edit"
                    onClick={() => setShowCustomProfileImageModal(true)}
                >
                    <UilPen />
                </span>
                {showCustomProfileImageModal && (
                    <CustomModal close={true} onClose={() => {
                        fetchWhitelabelData();
                        setShowCustomProfileImageModal(false);
                    }} width={500} title="Upload a custom image">
                        <div className="attachmentItem">
                            <div className="attachmentItemContent">
                                <div>
                                    <img
                                        id="custom-profile-image"
                                        width={forFavicon ? 64 : 180}
                                        height={forFavicon ? 64 : 30}
                                        src={customProfileImage instanceof File ? URL.createObjectURL(customProfileImage) : customProfileImage}
                                        style={{ margin: 'auto', backgroundPosition: 'center center', paddingBlock: '1.25rem' }}
                                        alt="Selected Image"
                                    />
                                </div>
                            </div>
                        </div>
                        <MyDropzone items={customProfileImage} setItems={setCustomProfileImage} maxSize={100 * 1024} maxDimensions={dimensions} />
                        <CustomButton
                            title="Save"
                            variant="primary"
                            style={{ margin: '1rem 3px 3px auto' }}
                            onClick={handleSaveImage}
                            disabled={profileImage === customProfileImage}
                        />
                    </CustomModal>
                )}
            </div>
        );
    };

    return (
        <div>
            <Animate>
                <div className="account-whitelabel">
                    <PageTitle
                        title="Whitelabel Settings"
                        description="Make SalesBlink your own by adding your branding."
                        headerStyle={{ marginTop: 0 }}
                    />
                    <div className="card" style={{ marginTop: '1rem' }}>
                        <div>
                            <div className="flex">
                                <h2>Name</h2>
                                <UilQuestionCircle 
                                    size={22} 
                                    id='w-name'
                                    style={{ cursor: 'pointer', marginLeft: 5 }} 
                                />
                                <Tooltip anchorSelect="#w-name" place="right">Set a custom name, will be visible everywhere.</Tooltip>
                            </div>
                            <div className="flex gap-4 items-start mt-2">
                                <TextInput
                                    value={agencyName}
                                    placeholder="Enter agency name..."
                                    onChange={(n, value) => setAgencyName(value)}
                                    className='input-width'
                                />
                                <CustomButton
                                    title="Save"
                                    variant="secondary"
                                    style={{ marginTop: 0, padding: 12 }}
                                    onClick={handleAgencyNameChange}
                                    disabled={agencyName?.trim() === whitelabelDetails?.agencyName}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex">
                                <h2>Logo</h2>
                                <UilQuestionCircle 
                                    size={22} 
                                    id='w-logo'
                                    style={{ cursor: 'pointer', marginLeft: 5 }} 
                                />
                                <Tooltip anchorSelect="#w-logo" place="right">Set your logo to whitelabel SalesBlink.</Tooltip>
                            </div>
                            <div className="flex gap-4 mt-2">
                                <CustomProfileImageDashboard
                                    profileImage={whitelabelDetails.headerLogo}
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="flex">
                                <h2>Favicon</h2>
                                <UilQuestionCircle 
                                    size={22} 
                                    id='w-favicon'
                                    style={{ cursor: 'pointer', marginLeft: 5 }} 
                                />
                                <Tooltip anchorSelect="#w-favicon" place="right">Set your favicon to whitelabel SalesBlink.</Tooltip>
                            </div>
                            <div className="flex gap-4 mt-2">
                                <CustomProfileImageDashboard
                                    forFavicon={true}
                                    profileImage={whitelabelDetails.favicon}
                                />
                            </div>
                        </div>
                        {showCustomDomainModal && <CustomModal title="Add Custom Domain" close={true} onClose={() => setShowCustomDomainModal(false)}>
                            <div style={{ padding: 8 }}>
                                <div>
                                    <p>
                                        Please add the following details to your domain's DNS record and
                                        click Verify Record to continue.
                                    </p>
                                    <table className="domainTable">
                                        <tr>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Value</th>
                                        </tr>
                                        <tr>
                                            <td>{getSubdomain(customDomain) === '' ? "A Record" : "CNAME Record"}</td>
                                            <td>{customDomain}</td>
                                            <td>
                                                {getSubdomain(customDomain) === '' ? '161.35.255.193' : 'run.salesblink.io'}
                                            </td>
                                        </tr>
                                    </table>
                                    <p style={{ margin: 10 }}>
                                        Note: It can take upto 24 hours for your DNS records to update.
                                    </p>
                                </div>
                                <Button
                                    text="Verify Record"
                                    variant="primary"
                                    action={handleCustomDomainVerification}
                                    className="ml-auto"
                                />
                            </div>
                        </CustomModal>}
                        <div className="mt-4">
                            <div className="flex">
                                <h2>Domain</h2>
                                <UilQuestionCircle 
                                    size={22} 
                                    id='w-custom-domain'
                                    style={{ cursor: 'pointer', marginLeft: 5 }} 
                                />
                                <Tooltip anchorSelect="#w-custom-domain" place="right">Set your domain or sub-domain to whitelabel SalesBlink.</Tooltip>
                            </div>
                            <div className="flex gap-4 items-start mt-2">
                                <TextInput
                                    value={customDomain}
                                    placeholder="Enter Custom Domain or Sub-Domain..."
                                    onChange={(n, value) => setCustomDomain(value)}
                                    className='input-width'
                                    disabled={whitelabelDetails?.customDomain ? true : false}
                                />
                                {whitelabelDetails?.customDomain && <div className="mt-3">
                                    <UilCheckCircle id='verificationStatus' color={whitelabelDetails?.isVerified ? '#62d187' : '#d7d7d7'} />
                                    <Tooltip anchorSelect="#verificationStatus" place="top">{whitelabelDetails?.isVerified ? 'Domain is Active' : 'Pending Verification'}</Tooltip>
                                </div>}
                                {!whitelabelDetails?.customDomain ? <CustomButton
                                    title='Add'
                                    variant="secondary"
                                    style={{ marginTop: 0, padding: 12 }}
                                    onClick={handleCustomDomainChange}
                                    disabled={customDomain?.trim() === whitelabelDetails?.customDomain}
                                /> : <CustomButton
                                    title='Verify'
                                    variant="secondary"
                                    style={{ marginTop: 0, padding: 12, display: whitelabelDetails?.isVerified ? 'none' : 'flex' }}
                                    onClick={() => setShowCustomDomainModal(true)}
                                />}
                                {whitelabelDetails?.customDomain && <div id='deleteDomain'>
                                    <CustomButton
                                        icon={<UilTrash className='cursor-pointer' color='#ef898c' />}
                                        variant="secondary"
                                        style={{ marginTop: 0, padding: 12, outline: '3px solid #ef898c', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0 }}
                                        onClick={deleteCustomDomain}
                                    />
                                    <Tooltip anchorSelect="#deleteDomain" place="top">Delete</Tooltip>
                                </div>}
                            </div>
                        </div>
                        <div className="mt-4">
                            <Checkbox
                                name="whitelabel-meeting-scheduler"
                                option="Whitelabel Meeting Scheduler"
                                onChange={() => {
                                    setLoading(true);
                                    handleWhitelabelCalendarChange();
                                }}
                                checked={whitelabelCalendar}
                                disabled={loading}
                                tooltip="When checked, SalesBlink branding will not show up on meeting scheduler."
                            />
                        </div>
                    </div>
                </div>
            </Animate>
        </div>
    );
}
