import React, { useEffect, useState, useRef, useContext } from 'react'
import { UilCheck } from '@iconscout/react-unicons';
import { useStripe } from '@stripe/react-stripe-js';
import Axios from 'axios';
import { Col, Row } from 'react-grid-system';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomModal from 'components/custom/customModal';
import { AuthContext, Loader, PaymentForm } from 'components/lib';
import CustomButton from 'components/custom/customButton';
import './plan.scss';

const planDev = {
    scale: {
        month: "price_1NlO9xGdPjcvdGep9YF3r6AD",
        year: "price_1NlO9xGdPjcvdGepEIlXuvy3",
    },
    growth: {
        month: "price_1NlOBWGdPjcvdGepZTcoisuX",
        year: "price_1NlOBWGdPjcvdGepiG9E8ygr",
    },
    business: {
        month: "price_1NlOCSGdPjcvdGep9ttru8rN",
        year: "price_1NlOCTGdPjcvdGepbVHNdFQg",
    },
    agency_scale: {
        month: "price_1PgjF9GdPjcvdGepx9QOieRr",
        year: "price_1PgjF9GdPjcvdGepAoEr0Y4B",
    },
    agency_growth: {
        month: "price_1PgjFVGdPjcvdGeptaUFevCe",
        year: "price_1PgjFwGdPjcvdGepfojH6Ta2",
    },
    agency_business: {
        month: "price_1PgjHSGdPjcvdGeps4pAOsah",
        year: "price_1PgjHSGdPjcvdGepSuG2AaXK",
    }
};

const planProd = {
    scale: {
        month: "price_1NmacEGdPjcvdGepmmuHKhFx",
        year: "price_1NmacEGdPjcvdGep2Es38a71",
    },
    growth: {
        month: "price_1NmacNGdPjcvdGepsKomGWo4",
        year: "price_1NmacNGdPjcvdGepXIPMMVgF",
    },
    business: {
        month: "price_1NmacTGdPjcvdGep92G9GQKO",
        year: "price_1NmacTGdPjcvdGepI1laNWPb",
    },
    agency_scale: {
        month: "price_1Ph1psGdPjcvdGepRNZruWNA",
        year: "price_1Ph1psGdPjcvdGepnbYjAxPc",
    },
    agency_growth: {
        month: "price_1Ph1pwGdPjcvdGepxUk00D9W",
        year: "price_1Ph1pwGdPjcvdGepYS6hYU2e",
    },
    agency_business: {
        month: "price_1Ph1pzGdPjcvdGepT5TslN8s",
        year: "price_1Ph1pzGdPjcvdGep8Cer6g7m",
    }
};

let planObj = process.env.NODE_ENV === 'production' ? planProd : planDev;

const plansArr = [
    {
        title: 'Scale',
        price: 29,
        price_yearly: 25,
        monthly_plan_id: planObj.scale.month,
        yearly_plan_id: planObj.scale.year,
        features: [
            <span>Send upto <span className='highlight'>6000</span> emails</span>,
            <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>, 
            <span className='highlight tooltip' data-tooltip-content='Generate Emails with BlinkGPT AI'>BlinkGPT AI Email Templates</span>,
            <span>Unlimited <span className='highlight tooltip' data-tooltip-content='Improve email deliverability with a Pool of 10,000+ real inboxes'>Email WarmUp</span></span>, 
            <span>Unified <span className='highlight'>Inbox</span></span>,
            <span>Email <span className='highlight'>Address Rotation</span></span>,
            <span>Automated <span className='highlight'>Follow-Ups</span></span>,
        ]
    },
    {
        title: 'Growth',
        price: 99,
        price_yearly: 79,
        monthly_plan_id: planObj.growth.month,
        yearly_plan_id: planObj.growth.year,
        features: [
            <span>Everything in Scale and</span>,
            <span>Send upto <span className='highlight'>50,000</span> emails</span>,
            <span className='highlight'>BlinkGPT AI Sequences</span>,
            <span>BlinkGPT AI Personalized Emails</span>,
            <span className='highlight'>Unlimited Team Members</span>,
            <span>Meeting Scheduler</span>,
            <span>CRM Integrations</span>,
        ]
    },
    {
        title: 'Business',
        price: 199,
        price_yearly: 149,
        monthly_plan_id: planObj.business.month,
        yearly_plan_id: planObj.business.year,
        features: [
            <span>Everything in Growth</span>,
            <span>Send upto <span className='highlight'>100000</span> emails</span>,
            <span>Outreach Tasks</span>,
            <span>Team Reports</span>,
            <span>Priority Support</span>,
        ]
    },
    // AGENCY PLANS
    {
        title: 'Agency Scale',
        price: 99,
        price_yearly: 999,
        monthly_plan_id: planObj.agency_scale?.month,
        yearly_plan_id: planObj.agency_scale?.year,
        features: [
            <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Add upto <span className='highlight'>3</span> Workspaces</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>60,000</span> emails</span>,
        ]
    },
    {
        title: 'Agency Growth',
        price: 199,
        price_yearly: 1990,
        monthly_plan_id: planObj.agency_growth?.month,
        yearly_plan_id: planObj.agency_growth?.year,
        features: [
            <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Add upto <span className='highlight'>10</span> Workspaces</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>150,000</span> emails</span>,
        ]
    },
    {
        title: 'Agency Business',
        price: 299,
        price_yearly: 2999,
        monthly_plan_id: planObj.agency_business?.month,
        yearly_plan_id: planObj.agency_business?.year,
        features: [
            <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Add upto <span className='highlight'>30</span> Workspaces</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>300,000</span> emails</span>,
        ]
    },
];

export default function ConfirmPlanUpdate() {
    const [newPlan, setNewPlan] = useState(null)
    const [oldPlan, setOldPlan] = useState(null)
    const [loading, setLoading] = useState(false);
    const [disableBuy, setDisableBuy] = useState(false);
    const [viewAddCard, setViewAddCard] = useState(false);
    const [expired, setExpired] = useState(false);
    const [couponValue, setCouponValue] = useState("");
    const [checkCoupon, setCheckCoupon] = useState(null);
    const [checkCouponMessage, setCheckCouponMessage] = useState("");
    const context = useContext(AuthContext);
    const timeoutRef = useRef(null);
    const { id } = useParams()
    const navigator = useNavigate();
    const stripe = useStripe();

    useEffect(() => {
        let activePlan = sessionStorage.getItem('activePlan')
        if(activePlan) {
            let oldPlanInfo = plansArr.find(plan => plan.monthly_plan_id === activePlan || plan.yearly_plan_id === activePlan)
            if(oldPlanInfo) setOldPlan({
                title: oldPlanInfo.title,
                price: oldPlanInfo.price,
                price_yearly: oldPlanInfo.price_yearly,
                features: oldPlanInfo.features,
                isYearly: oldPlanInfo.monthly_plan_id === activePlan ? false : true
            })
        }
        if(id) {
            let newPlanInfo = plansArr.find(plan => plan.monthly_plan_id === id || plan.yearly_plan_id === id)
            if(newPlanInfo) setNewPlan({
                title: newPlanInfo.title,
                price: newPlanInfo.price,
                price_yearly: newPlanInfo.price_yearly,
                features: newPlanInfo.features,
                isYearly: newPlanInfo.monthly_plan_id === id ? false : true
            })
        }
    }, [id])

    const buyNow = async (coupons) => {
        if(disableBuy) return
        if(!newPlan) return Swal.fire({
            icon: 'error',
            title: `Oops!`,
            html: `<h4>Please refresh page and try again.</h4>`,
            confirmButtonText: `Okay`,
            confirmButtonColor: '#0066FF',
        });
        setDisableBuy(true);

        try {
            if(oldPlan) {
                if(oldPlan?.isYearly !== newPlan?.isYearly) {
                    let cancelledSuccess = await cancelSubscriptionForUpdatePlanInterval();
                    if(!cancelledSuccess) {
                        Swal.fire({
                            icon: 'error',
                            title: `Oops!`,
                            html: `<h4>Please try again later or contact customer support.</h4>`,
                            confirmButtonText: `Okay`,
                            confirmButtonColor: '#0066FF',
                        });
                        setDisableBuy(false);
                        return
                    }
                }
            }

            let res = await Axios.patch('/api/account/plan', {
                subscriptions: [{
                    price: id,
                    quantity: 1
                }],
                coupons
            });
            if (res.data.requires_payment_action) {
                const stripeRes = await stripe.handleCardPayment(
                    res.data.client_secret
                );
                if (stripeRes.error) {
                    setLoading(false);
                    context.handleError(stripeRes.error.message);
                    return false;
                } else {
                    // re-send the form
                    res = await Axios.patch('/api/account/plan', {
                        subscriptions: [{
                            price: id,
                            quantity: 1
                        }],
                        stripe: res.data,
                        coupons
                    });
                }
            }
            setDisableBuy(false);
            sessionStorage.setItem('subscribed_plan', JSON.stringify({
                name: newPlan?.title,
                plan: res.data.data.plans.length > 0 ? res.data.data.plans[0] : {},
                subscriptionID: res.data.data.subscriptionID,
                coupons
            }))
            navigator('/subscribed')
        } catch (error) {
            console.log("TEST ERROR ", error)
            setLoading(false);
            setDisableBuy(false);
            if (error.response?.status === 409) return setViewAddCard(true);
            let errMsg = 'Error updating plan. Try after sometime.';
            if (error.response?.data?.message) errMsg = error.response?.data?.message;
            Swal.fire({
                icon: 'error',
                title: `Oops!`,
                html: `<h4>${errMsg}</h4>`,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
            });
        }
    };

    const cancelSubscriptionForUpdatePlanInterval = async () => {
        try {
            await Axios.delete(`/api/account/plan?diff_interval=true`);
            return true
        } catch (error) {
            console.log("ERR ", error);
            return false;
        }
    }

    return (
        <>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        zIndex: 20,
                        bottom: 0,
                        top: 0,
                    }}
                >
                    <Loader />
                </div>
            )}

            {viewAddCard && (
                <CustomModal close={true} title='One Last Step…' onClose={() => {
                    setViewAddCard(false)
                    setCheckCoupon(null);
                    setCheckCouponMessage("");
                    setCouponValue("");
                    clearTimeout(timeoutRef.current);
                }}>
                    <div style={{ padding: 15 }}>
                        <PaymentForm
                            data={{
                                token: {
                                    label: 'Credit Card Details',
                                    type: 'creditcard',
                                    required: true,
                                },
                                coupon: {
                                    label: 'Do you have a coupon code?',
                                    type: 'text',
                                    placeholder: 'Enter your coupon code',
                                    errorMessage: checkCouponMessage,
                                    valid: !!!checkCouponMessage,
                                    value: couponValue,
                                },
                            }}
                            onChange={async function ({input, value, valid}) {
                                if(input === "coupon") {
                                    setCouponValue(value);

                                    if(value) {   
                                        clearTimeout(timeoutRef.current);
                                        
                                        timeoutRef.current = setTimeout(async () => {
                                            try {
                                                const response = await Axios.get(`/api/account/check-coupon?coupon=${value}`)
                                                setCheckCoupon(response.data.coupons);
                                                setCheckCouponMessage("");
                                            } catch(error) {
                                                setCheckCouponMessage(error.response.data.message);
                                                setCheckCoupon(null);
                                            }
                                        }, 1500);
                                    }
                                }
                            }}
                            updateOnChange={true}
                            url="/api/account/create-customer"
                            method="POST"
                            buttonText={function () {
                                const price = newPlan?.isYearly ? newPlan?.price_yearly * 12 : newPlan?.price;

                                const lessAmount = checkCoupon?.amount_off;
                                const lessPercentage = checkCoupon?.percent_off;

                                let term = ""
                                if(checkCoupon) {
                                    if(lessAmount) term = `$${lessAmount / 100} off `
                                    else if(lessPercentage) term = `${lessPercentage}% off `

                                    if(checkCoupon?.duration === "forever" || checkCoupon?.duration === "once") {
                                        term += checkCoupon?.duration
                                    } else if(checkCoupon?.duration === "repeating") {
                                        term += `for ${checkCoupon?.duration_in_months} months`;
                                    }
                                }

                                return (
                                <div>
                                    Subscribe to {newPlan?.title} Plan{' '}
                                    {newPlan?.isYearly ? 'Yearly' : 'Monthly'} at{' '}
                                    ${price}
                                    {'  '} {checkCoupon?`(${term})`:""}
                                </div>
                                );
                            }()}
                            callback={(res) => {
                                if (res.data.success) {
                                    setViewAddCard(false);
                                    buyNow(res.data.coupon);
                                }
                            }}
                        />
                    </div>
                </CustomModal>
            )}
        
            <div className='billing'>
                <Row>
                    <Col md={oldPlan ? 3 : 4}></Col>
                    {oldPlan && <Col md={3}>
                        <div className="pricing-plan-card">
                            <h2 className="title">{oldPlan?.title} (Old Plan)</h2>

                            <div className='price'>
                                {/* show monthly price */}
                                {!oldPlan?.isYearly && <><span style={{ fontSize: "1.75rem" }}>${oldPlan?.price}</span> / month</>}

                                {/* show yearly price with monthly price crossed out */}
                                {oldPlan?.isYearly && <><span style={{fontSize: "1.75rem"}}>${oldPlan?.price_yearly}</span> <span style={{textDecoration: "line-through"}}>${oldPlan?.price}</span> / month</>}

                                <p style={{color: "#757575", fontSize: "0.8rem", visibility: oldPlan?.isYearly ? "visible" : "hidden"}}>Billed Annually as ${oldPlan?.price_yearly * 12}</p>
                            </div>
                            <ul className="features">
                                {oldPlan?.features.map((feature, index) => (
                                    <li key={index}><span><UilCheck className="check"/></span>{feature}</li>
                                ))}
                            </ul>
                            <div className='mb-3 flex justify-center' style={{ marginTop: -20 }}>
                                <a href='https://salesblink.io/pricing#features' className='csCustomLink' style={{ fontSize: 16 }} target='_blank'>View all Features</a>
                            </div>
                        </div>
                    </Col>}
                    {newPlan && <Col md={oldPlan ? 3 : 4}>
                        <div className="pricing-plan-card">
                            <h2 className="title">{newPlan?.title} (New Plan)</h2>

                            <div className='price'>
                                {/* show monthly price */}
                                {!newPlan?.isYearly && <><span style={{ fontSize: "1.75rem" }}>${newPlan?.price}</span> / month</>}

                                {/* show yearly price with monthly price crossed out */}
                                {newPlan?.isYearly && <><span style={{fontSize: "1.75rem"}}>${newPlan?.price_yearly}</span> <span style={{textDecoration: "line-through"}}>${newPlan?.price}</span> / month</>}

                                <p style={{color: "#757575", fontSize: "0.8rem", visibility: newPlan?.isYearly ? "visible" : "hidden"}}>Billed Annually as ${newPlan?.price_yearly * 12}</p>
                            </div>
                            <ul className="features">
                                {newPlan?.features.map((feature, index) => (
                                    <li key={index}><span><UilCheck className="check"/></span>{feature}</li>
                                ))}
                            </ul>
                            <div className='mb-3 flex justify-center' style={{ marginTop: -20 }}>
                                <a href='https://salesblink.io/pricing#features' className='csCustomLink' style={{ fontSize: 16 }} target='_blank'>View all Features</a>
                            </div>
                        </div>
                    </Col>}
                    <Col md={oldPlan ? 3 : 4}></Col>
                </Row>
                {newPlan && <div className='flex m-auto mt-4' style={{ width: 550 }}>
                    <CustomButton
                        variant={`primary`}
                        disabled={disableBuy}
                        onClick={() => buyNow()}
                        title={"Confirm Plan Update"}
                        style={{ width: 250, margin: 'auto' }}
                    />
                    <CustomButton
                        variant={`primary red`}
                        disabled={disableBuy}
                        onClick={() => {
                            sessionStorage.removeItem('activePlan')
                            navigator('/account/billing')
                        }}
                        title={"Cancel Plan Update"}
                        style={{ width: 250, margin: 'auto' }}
                    />
                </div>}
            </div>
        </>
    )
}
